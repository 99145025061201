import { lazy, ReactNode, useContext } from "react";
import { Link as TanstackLink } from "@tanstack/react-router";
import { AppRouteValues } from "@repo/utils";
import { I18nContext } from "@repo/i18n-config";

// TODO investigate TS
// @ts-ignore
const NextLink = lazy(() => import("next/link"));

interface ImportMetaEnv {
  readonly VITE_IS_VITE: string;
}

interface ImportMeta {
  readonly env: ImportMetaEnv;
}

export interface LinkWrapperProps {
  to: AppRouteValues;
  children: ReactNode;
  query?: Record<string, string>;
  noUnderline?: boolean;
}

export const LinkWrapper = ({
  to,
  children,
  query,
  noUnderline,
}: LinkWrapperProps) => {
  const { lang } = useContext(I18nContext);
  if ((import.meta as unknown as ImportMeta).env?.VITE_IS_VITE)
    return (
      <TanstackLink
        to={to}
        style={{ ...(noUnderline && { textDecoration: "none" }) }}
      >
        {children}
      </TanstackLink>
    );
  else
    return (
      <NextLink
        href={{ pathname: `/${lang}${to}`, query }}
        style={{ ...(noUnderline && { textDecoration: "none" }) }}
      >
        {children}
      </NextLink>
    );
};
