"use client";

import { useCallback, useContext } from "react";
import {
  AdvancedMarker,
  AdvancedMarkerProps,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { Typography } from "@mui/material";
import { Button } from "../Button";
import { getImageUrl } from "@repo/utils";
import loymeeMapPin from "../assets/loymee-map-pin.svg";
import { I18nContext, useTranslation } from "@repo/i18n-config";
import { useMapPlacesContext } from "./MapPlacesContext";

interface MarkerProps {
  markerProps: AdvancedMarkerProps;
  place: google.maps.places.PlaceResult;
  onSelect: (place: google.maps.places.PlaceResult) => void;
  selectedPlace: google.maps.places.PlaceResult | null;
}

export const Marker = ({
  markerProps,
  place,
  onSelect,
  selectedPlace,
}: MarkerProps) => {
  const { lang } = useContext(I18nContext);
  const { t } = useTranslation(lang, "ui");

  const { openInfoWindowPlaceId, setOpenInfoWindowPlaceId } =
    useMapPlacesContext();
  const [markerRef, marker] = useAdvancedMarkerRef();

  const handleMarkerClick = useCallback(() => {
    setOpenInfoWindowPlaceId(place.place_id!);
  }, []);

  const handleSelectClick = useCallback(() => {
    onSelect(place);
    setOpenInfoWindowPlaceId(null);
  }, [onSelect, place]);

  const handleClose = useCallback(() => {
    setOpenInfoWindowPlaceId(null);
  }, []);

  const isPlaceSelected = selectedPlace?.place_id === place.place_id;

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={handleMarkerClick}
        {...markerProps}
      >
        <img
          src={getImageUrl(loymeeMapPin)}
          alt="Loymee"
          style={{
            height: "48px",
            filter: "drop-shadow(0px 4px 8px #22222288)",
          }}
        />
      </AdvancedMarker>
      {openInfoWindowPlaceId === place.place_id && (
        <InfoWindow
          anchor={marker}
          maxWidth={300}
          onCloseClick={handleClose}
          headerContent={
            <Typography
              variant="body2"
              sx={(theme) => ({ color: theme.palette.background.default })}
            >
              {place.name}
            </Typography>
          }
        >
          <Typography
            variant="caption"
            sx={(theme) => ({ color: theme.palette.background.default })}
          >
            {place.formatted_address}
          </Typography>
          <Button
            sx={{
              mt: 1,
              display: "block",
            }}
            size="small"
            onClick={handleSelectClick}
          >
            {t(`MapComponent.isPlaceSelected.${isPlaceSelected}`)}
          </Button>
        </InfoWindow>
      )}
    </>
  );
};
